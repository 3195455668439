<template>
  <div :class="getClass">
    <BaseIcon :type="color === 'red' ? 'alert' : color === 'yellow' ? 'help' : 'ok'"/>
    <p v-html="text"></p>
    <div
      v-if="dropdownOptions"
      @blur="closeDropdown" tabindex="-1"
      class="base-error__dropdown"
    >
      <img
        @click="openDropdown"
        :src="require('@/assets/icons/menu.png')" class="base-error__dropdown-img" alt="img"
      />
      <div
        v-for="option in dropdownOptions"
        :id="id"
        class="base-error__dropdown-list-options"
      >
        <div class="base-error__dropdown-option" @click="option.method">{{option.name}}</div>
      </div>
    </div>
  </div>
</template>

<script>
  import BaseIcon from '@/components/ui/BaseIcon'
  import { computed } from 'vue'
  export default {
    name: 'BaseError',
    components: {
      BaseIcon
    },
    props: {
      id: {
        type: String || Number,
        default: () => null
      },
      dropdownOptions: {
        type: Array,
        default: () => null
      },
      color: {
        type: String,
        required: true,
        default: () => null
      },
      text: {
        type: String,
        required: true,
        default: () => null
      },
      side: {
        type: String,
        default: () => 'center'
      },
    },
    computed: {
     getClass() {
       return [
         'base-error',
         `base-error--${this.color}`,
         `base-error--${this.side}`,
         this.dropdownOptions ? `base-error--with-dropdown` : ''
        ]
     }
    },
    methods: {
      closeDropdown() {
        const dropdown = document.getElementById(this.id)
        dropdown.style.display = 'none'
      },
      openDropdown() {
        const dropdown = document.getElementById(this.id)
        dropdown.style.display = 'block'
      },
    }
  }
</script>

<style lang="scss" scoped>
.base-error {
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: left;
  max-width: 50%;
  margin: 0 auto;
  padding: 5px 0;
  position: relative;

  & > *:first-child {
   margin: 0 15px;
  }

  &--red {
    color: $color-active-red;
    background-color: $color-light-red;
  }

  &--yellow {
    color: $color-active-yellow;
    background-color: $color-light-yellow;
  }

  &--green {
    color: $color-active-green;
    background-color: $color-light-green;
  }

  &--left {
    max-width: 75%;
    margin: 0;
    padding-right: 15px;
  }

  &--right {
    max-width: 100%;
    margin: 0;
    padding-right: 15px;
  }

  &--with-dropdown {
    padding-right: 35px;
  }

  &__dropdown {
    cursor: pointer;
    outline: none;
    position: absolute;
    margin: auto 0 auto auto;
    right: 0;
  }

  &__dropdown-img:hover {
    background-color: $color-grey-6;
  }

  &__dropdown-img {
    margin-right: 5px;
  }

  &__dropdown-list-options {
    display: none;
    position: absolute;
    left: revert;
    right: 0;
    z-index: 1;
    width: max-content;
    border: 1px solid $color-grey-4;
    @extend .c-bg-w;

    > div {
      padding: 10px;
      min-height: 30px;
      cursor: pointer;
      color: $color-grey-1;
      //@extend .pointer, .hover;
    }

    > div:hover {
      background-color: $color-grey-4;
    }

    :not(:last-child) {
      border-bottom: .1rem solid $color-grey-4;
    }
  }
}
</style>
